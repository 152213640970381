export default async function color(theme) {
  const root = document.documentElement;
  if (theme === "ThemeViolet") {
    root.style.setProperty("--color-violet-100", "110 79 250");
    root.style.setProperty("--color-violet-90", "125 97 250");
    root.style.setProperty("--color-violet-80", "142 117 250");
    root.style.setProperty("--color-violet-60", "168 147 251");
    root.style.setProperty("--color-violet-40", "198 186 253");
    root.style.setProperty("--color-violet-20", "226 219 255");
    root.style.setProperty("--color-violet-10", "241 237 255");
    root.style.setProperty("--color-main", "251 250 255");
  } else {
    root.style.setProperty("--color-violet-100", "23 84 166");
    root.style.setProperty("--color-violet-90", "41 97 171");
    root.style.setProperty("--color-violet-80", "61 113 181");
    root.style.setProperty("--color-violet-60", "90 137 196");
    root.style.setProperty("--color-violet-40", "125 166 213");
    root.style.setProperty("--color-violet-20", "181 207 236");
    root.style.setProperty("--color-violet-10", "218 233 246");
    // root.style.setProperty("--color-main", "1 1 1");
    root.style.setProperty("--color-main", "245 245 250");
  }

  return theme;
}
