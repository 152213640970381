import React, { useState, useEffect } from "react";
import user_api from "../../ApiCallExpress/user_api.js";
import styles from "../../pages/userAccessPage/StylingForUseraccess.js";
import Logo from "../logo/biglogo.js";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { sessionService } from "redux-react-session";
import globalStore from "../../shared/globalStore.js";

export default function ResetPassword() {
  useEffect(() => {
    // document.title = "Wiz-Reset Password";

    const title = document.querySelector("title");
    title.innerHTML = title.innerHTML + " - Reset Password";
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    user_id: parseInt(location.pathname.split("/")[2]),
    resetToken: location.pathname.split("/")[3],
    password: "",
    confirm_password: "",
  });
  const [inputError, setInputError] = useState({
    password: "",
    confirm_password: "",
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  var loadScreen = <progress className="progress max-w-sm "></progress>;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };
  const validateInput = (e) => {
    let { name, value } = e.target;
    setInputError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (user.confirm_password && value !== user.confirm_password) {
            stateObj["confirm_password"] =
              "Password and Confirm Password does not match.";
          } else if (value.length < 8) {
            stateObj[name] = "Password must be at least 8 characters.";
          } else if (value.length > 20) {
            stateObj[name] = "Password must be less than 20 characters.";
          } //have capital letter, number and special character
          else if (!/[A-Z]/.test(value)) {
            stateObj[name] =
              "Password must contain at least one capital letter.";
          } else if (!/[0-9]/.test(value)) {
            stateObj[name] = "Password must contain at least one number.";
          } else {
            stateObj["confirm_password"] = user.confirm_password
              ? ""
              : inputError.confirm_password;
          }
          break;

        case "confirm_password":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (user.password && value !== user.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };
  // const disabledInvalidPassword = () => {


  const handleSubmit = (event) => {
    event.preventDefault();
    user_api
      .reset(user.resetToken, user.password, user.user_id)
      .then((response) => {
        //check is string contain "incorrect Token"
        // if (response.data.includes("incorrect Token")) {
        if (response.error) {
          setMessage("Invalid Token");
        } else {
          setMessage("Password Reset Successfully");
        }
        setLoading(true);
        setTimeout(() => {
          user_api
            .logout(globalStore.getState().session.user.id)
            .then((res) => {});
          sessionService.deleteSession();
          sessionService.deleteUser();
          navigate("/");
        }, 3000);
        //console.log(response);
      })
      .catch((error) => {
        //console.log(error);
      });

    // call user api to reset password
  };

  const handleMessage = (event) => {
    setMessage("");
  };

  var message_style;
  if (message === "Password Reset Successfully") {
    message_style = (
      <div>
        <div className="pt-2 h-12 flex max-w-sm alert alert-success">
          <div className="justify-start">
            <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
              {message + " "}
            </p>
          </div>
          <button onClick={handleMessage} className="flex-none pt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current flex-shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        {loading !== false && loadScreen}
      </div>
    );
  } else {
    message_style = (
      <div className="pt-2 h-12 flex max-w-sm alert alert-error">
        <div className="justify-start">
          <p className={styles.Atag_size_dynamic + " font-Gilroy-l"}>
            {message + " "}
          </p>
        </div>
        <button onClick={handleMessage} className="flex-none pt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    );
  }
  const handleKeyDowm = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <div className="flex flex-col justify-center my-auto  sm:px-8 md:px-16 lg:px-16 xl:px-64 ">
      <Logo link="/"></Logo>
      <p
        className={
          "text-left sm:pb-2 md:pb-8 lg:pb-16 font-Gilroy-b text-dsblue-100" +
          styles.title_size_dynamic
        }
      >
        Reset Password Here
        <p className={styles.Atag_size_dynamic + message_style}>
          {message !== "" && message_style}
        </p>
      </p>
      <form
        className="flex flex-col"
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          handleKeyDowm(e);
        }}
      >
        <div className="flex flex-col pt-4">
          <label for="username" className={styles.input_lable}>
            Enter your new password
          </label>
          <input
            type="password"
            name="password"
            onChange={onInputChange}
            onBlur={validateInput}
            // placeholder="username"
            className={styles.input_box}
          />
          <div className={styles.input_error}>
            {inputError.password && (
              <span className="">{inputError.password}</span>
            )}
          </div>
        </div>
        <div className="flex flex-col pt-4">
          <label for="username" className={styles.input_lable}>
            Confirm Password
          </label>
          <input
            type="password"
            name="confirm_password"
            onChange={onInputChange}
            onBlur={validateInput}
            // placeholder="username"
            className={styles.input_box}
          />
          <div className={styles.input_error}>
            {inputError.confirm_password && (
              <span className="">{inputError.confirm_password}</span>
            )}
          </div>
        </div>
        <div className="flex justify-end pt-6">
          <button
            className={styles.btn_size_dynamic + " w-full"}
            type="submit"
            disabled={
              inputError.password !== "" ||
              inputError.confirm_password !== "" ||
              user.password === "" ||
              user.confirm_password === ""
            }
          >
            Save Password
          </button>
        </div>
        <p className="text-right pt-6 flex justify-end items-center">
          <svg
            className="fill-current text-violet-100"
            width="15"
            height="15"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.725 3.22509L8.25 1.75009L0 10.0001L8.25 18.2501L9.725 16.7751L2.95 10.0001L9.725 3.22509Z"
              // fill="#6F4FF9"
            />
          </svg>

          <a href="/login" className={" text-violet-90 lg:text-lg pl-2"}>
            Back to Login
          </a>
        </p>
      </form>
    </div>
  );
}
