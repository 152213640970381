import "./App.css";
import React from "react";
import PubRoute from "./routes/route";
import { Provider } from "react-redux";
import globalStore from "./shared/globalStore";
import axios from "axios";
import testfavicon from "./Asset/img/allclass.png";
import user_api from "./ApiCallExpress/user_api";
import config, { s3_bucket } from "./config";
import color from "./corlorConfig";

axios.defaults.withCredentials = true;

function App() {
  const apiURL = window.location.origin.includes("http://localhost:")
    ? "http://localhost:4000"
    : window.location.origin.replace("3000", "4000");

  const [title] = React.useState(document.querySelector("title"));
  const [User, setUser] = React.useState(globalStore.getState().session.user);
  const store = globalStore;
  React.useEffect(() => {
    const origin = window.location.origin;
    if (
      config.dynamic
        ? !config.main_origin_build.includes(window.location.origin)
        : !config.main_origin_dev.includes(window.location.origin) &&
          config.checkOrigin
    ) {
      console.log("origin", origin);

      color("ThemeNeutral");
      user_api
        .get_partner_by_origin(origin)
        .then((res) => {
          console.log(res);
          if (res) {
            // change rel="apple-touch-icon" to use the testfavicon
            const link = document.querySelector(
              "link[rel~='apple-touch-icon']"
            );
            // link.href = testfavicon;
            link.href = `https://${s3_bucket}.s3.amazonaws.com/${res.partnerFavicon}`;

            //  rel = "icon" to use the testfavicon
            const link2 = document.querySelector("link[rel~='icon']");
            link2.href = `https://${s3_bucket}.s3.amazonaws.com/${res.partnerSmallLogo}`;

            title.innerHTML = res.partnerName;
            color("ThemeNeutral");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (User.login_partner) {
        color("ThemeNeutral");
        // title.innerHTML = "test";
        title.innerHTML = User.login_partner.partnerName;
        // change rel="apple-touch-icon" to use the testfavicon
        const link = document.querySelector("link[rel~='apple-touch-icon']");
        link.href = `https://${s3_bucket}.s3.amazonaws.com/${User.login_partner.partnerFavicon}`;
        //  rel = "icon" to use the testfavicon
        const link2 = document.querySelector("link[rel~='icon']");
        link2.href =
          `https://${s3_bucket}.s3.amazonaws.com/` +
          User.login_partner.partnerSmallLogo;
        color("ThemeNeutral");
      } else {
        title.innerHTML = "Wiz";
      }
    }
    console.log("origin1");
  }, [window.location.origin, User]);
  return (
    <Provider store={globalStore}>
      <div className="App">
        {/* <header className="main-header">
        <a href="/react">reactApp</a>
        <a href="/socketPOC">socketPOC</a>
        <a href="/combined-route">combined-routeApp</a>
      </header> */}
        <div id="subapp-viewport1"></div>
        <PubRoute />
      </div>
    </Provider>
  );
}

export default App;
